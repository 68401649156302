<template>
  <div class="page-loader__wrapper" v-if="isLoadingPage">
    <sm-spiner class="page-loader" />
  </div>

  <div
    v-else-if="!isCorrectLink || showAlertMessage"
    class="page-loader__wrapper"
  >
    <p>Убедитесь что у вас дествительная ссылка</p>
  </div>

  <div v-else>
    <iframe class="intercon__video" :src="cameraURL.data"></iframe>
    <!-- !atsAccess.data?.Error && -->
    <sm-phone
      v-if="!atsAccess.isLoading"
      ref="phone"
      :options="userAgentOptions"
    />
    <p>
      Вы авторизовались под пользователем:
      {{ userAgentOptions?.authorizationUsername }}
    </p>
    <sm-button class="intercon__button" @click="onActivateIntercom"
      >Открыть дверь</sm-button
    >
    <sm-button colorType="danger" class="cancell__button" @click="onCancelCall">
      Отклонить вызов
    </sm-button>
  </div>
</template>

<script>
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers(
  'interactiveAccessObjects',
);
// components
import SmSpiner from '@/components/common/SmSpiner.vue';
import SmPhone from '@/components/phone/SmPhone.vue';
import SmButton from '@/components/common/forms/SmButton.vue';

export default {
  name: 'Index',

  components: {
    SmSpiner,
    SmButton,
    SmPhone,
  },

  data() {
    return {
      isLoadingPage: true,
      showAlertMessage: false,
    };
  },

  computed: {
    ...mapState(['cameraURL', 'atsAccess']),

    userAgentOptions() {
      if (this.atsAccess.data) {
        const {
          AtsNumber,
          // AtsPassword
        } = this.atsAccess.data;
        return {
          authorizationUsername: AtsNumber,
          authorizationPassword: 'nR97jA8',
          contactParams: { transport: 'wss' },
          transportOptions: {
            server: 'wss://ats.sm-center.ru:7443',
          },
        };
      }
      return null;
    },

    atsNumber() {
      const { atsNumber } = this.$route.query;
      return atsNumber || null;
    },

    objectID() {
      const { objectID } = this.$route.query;
      return objectID || null;
    },

    base() {
      const { base } = this.$route.query;
      return base || null;
    },

    token() {
      const { acx } = this.$route.query;
      return acx || null;
    },

    isCorrectLink() {
      return this.atsNumber && this.objectID && this.base && this.token;
    },
  },

  created() {
    this.setToken(this.token);
    this.setBase(this.base);

    if (this.isCorrectLink) {
      this.isLoadingPage = true;

      const getCameraUrlByInteractiveAccessObjects =
        this.getCameraUrlByInteractiveAccessObjects({
          objectID: this.objectID,
        });
      const getAtsAccess = this.getAtsAccess({ atsNumber: this.atsNumber });

      Promise.all([getCameraUrlByInteractiveAccessObjects, getAtsAccess])
        .catch(() => {
          this.showAlertMessage = true;
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    }
  },

  beforeDestroy() {
    this.resetState();

    localStorage.removeItem('token');
    localStorage.removeItem('base');
  },

  methods: {
    ...mapActions([
      'resetState',
      'setToken',
      'setBase',
      'getCameraUrlByInteractiveAccessObjects',
      'getAtsAccess',
      'activate',
    ]),

    onCancelCall() {
      this.$refs?.phone.onCancel();
    },

    onActivateIntercom() {
      this.activate({ objectId: this.objectID });
    },
  },
};
</script>

<style scoped>
.page-loader__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.intercon__video {
  width: 720px;
  height: 480px;
}

.intercon__button {
  margin-right: 15px;
}

.intercon__button,
.cancell__button {
  width: auto;
}
</style>
